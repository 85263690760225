import { useState, useEffect } from 'react';
import { Table, Form } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa';
import { TiDelete } from "react-icons/ti";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import Alert from 'react-bootstrap/Alert';
import "./listadoUsuarios.css";

function TableUsuarios() {

    const [show, setShow]                                       = useState(false);
    const [showDelete, setShowDelete]                           = useState(false);
    const [showAdd, setShowAdd]                                 = useState(false);
    const [deleteId, setDeleteId]                               = useState(false);
    const [items, setItems]                                     = useState([]);
    
    const [inputNombre, setInputNombre]                         = useState("");
    const [inputUsername, setInputUsername]                     = useState("");
    const [inputPhone, setInputPhone]                           = useState("");
    const [inputPassword, setInputPassword]                     = useState("");
    const [inputJerarquia, setInputJerarquia]                   = useState("");

    const [inputIdEdit, setInputIdEdit]                         = useState("");
    const [inputNombreEdit, setInputNombreEdit]                 = useState("");
    const [inputUsernameEdit, setInputUsernameEdit]             = useState("");
    const [inputPhoneEdit, setInputPhoneEdit]                   = useState("");
    const [inputPasswordEdit, setInputPasswordEdit]             = useState("");
    const [inputJerarquiaEdit, setInputJerarquiaEdit]           = useState("");
    const [guardando, setGuardando]                             = useState(false);

    const [showAlert, setShowAlert]                             = useState(false);
    // const [showAlertError, setShowAlertError]                   = useState(true);


    const handleClose = () => setShow(false);
    const handleCloseDelete = () => setShowDelete(false);
    const handleCloseAdd = () => setShowAdd(false);


    const handleShow = (item_id) => {
        setShow(true);
    
        let ud = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
                baseURL: '',
                timeout: 5000,
                headers: {'content-type': 'application/json', 'Authorization': 'Bearer '+userData.access_token}
                });
        
            instance.get('api/get-user/'+item_id)
            .then(response => {
                setInputIdEdit(response.data.data[0].id)
                setInputNombreEdit(response.data.data[0].name)
                setInputUsernameEdit(response.data.data[0].username)
                setInputPhoneEdit(response.data.data[0].phone)
                setInputJerarquiaEdit(response.data.data[0].jerarquia)
            })

    }

    const handleShowAdd = () => {
        setShowAdd(true);
    }

    const handleShowDelete = (item_id) => {
        setShowDelete(true);
        setDeleteId(item_id)
    }

    const  handleDelete = () => {
        deleteProduct(deleteId)
    }


    const getData= async () => {
            
        let ud = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
                baseURL: '',
                timeout: 5000,
                headers: {'content-type': 'application/json', 'Authorization': 'Bearer '+userData.access_token}
                });
        
        //trackPromise(
            await instance.get('api/get-users')
            .then(response => {
                //console.log(response.data)
                setItems(response.data.data)
            })
        //)
    }

    useEffect(()=>{
        getData()
        },[])


    const deleteProduct=(id_prod)=>{
            
        let ud = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
                baseURL: '',
                timeout: 5000,
                headers: {'content-type': 'application/json', 'Authorization': 'Bearer '+userData.access_token}
                });
        
        trackPromise(
            instance.delete('api/delete-user/'+id_prod)
            .then(response => {
                //console.log(response.status)
                if(response.status === 200){
                    setShowDelete(false);
                    setDeleteId("")
                    getData()
                }
            })
        );
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setGuardando(true)
        if( inputNombre && inputUsername && inputPhone && inputPassword){
          
          var data_send = {
            "name": inputNombre,
            "username": inputUsername,
            "phone": inputPhone,
            "password": inputPassword,
            "jerarquia": inputJerarquia
          }
    
          let ud = sessionStorage.getItem('userData');
          let userData = JSON.parse(ud);
    
          const instance = axios.create({
                  baseURL: '',
                  timeout: 5000,
                  headers: {'content-type': 'application/json', 'Authorization': 'Bearer '+userData.access_token}
                  });
            
          instance.post('api/oauth/signup', data_send)
          .then(response => {

              if(response.status === 200){
                setInputNombre("");
                setInputUsername("");
                setInputPhone("");
                setInputPassword("");
                setInputJerarquia("");
                setShowAdd(false)
                setShowAlert(true)
                getData()
                setGuardando(false)
              }
          })
          .catch(function (error) {
           
                if(error.response.status === "401"){
                  console.log("se venció el token, please update it")
                  window.location.replace("./refresh-token");
                  
                }
          });
    
    
        }
        else{
          //return error
          alert("Faltan datos");
          setGuardando(false)
        }
        
    }

    const handleSubmitEdit = async (event) => {

        event.preventDefault();
        setGuardando(true)

        if( inputNombreEdit && inputUsernameEdit && inputPhoneEdit){
          
          var data_send = {
            "name": inputNombreEdit,
            "username": inputUsernameEdit,
            "phone": inputPhoneEdit,
            "password": inputPasswordEdit,
            "jerarquia": inputJerarquiaEdit
          }
    
          let ud = sessionStorage.getItem('userData');
          let userData = JSON.parse(ud);
    
          const instance = axios.create({
                  baseURL: '',
                  timeout: 5000,
                  headers: {'content-type': 'application/json', 'Authorization': 'Bearer '+userData.access_token}
                  });
            
            instance.put('/api/update-user/'+inputIdEdit, data_send)
            .then(response => {
                console.log(response)
                if(response.data.status === "200"){
                    // setInputNombreEdit("");
                    // setInputExistenciasEdit("");
                    setShow(false)
                    setShowAlert(true)
                    getData()
                    setGuardando(false)
                }
            })
            .catch(function (error) {
                if(error.response.status === "401"){
                console.log(error)
                
                }
            });
    
    
        }
        else{
          //return error
          alert("Faltan datos");
          setGuardando(false)
        }
        
    }
    

  return (
    <>
    <FaEdit onClick={() => handleShowAdd()}  />

    <Alert className="mt-4" key="success" variant="success" show={showAlert} onClose={() => setShowAlert(false)} dismissible>
        El usuario se guardó exitosamente
    </Alert>


    <Table striped bordered hover responsive>
      <thead>
        <tr>
            <th>#</th>
            <th>Nombre</th>
            <th>Username</th>
            <th>Teléfono</th>
            <th>Foto</th>
            <th>Jerarquía</th>
            <th></th>
        </tr>
      </thead>
      <tbody>
      {
        items && items.length>0 && items.map( (item) => 
            (
                <tr>
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    <td>{item.username}</td>
                    <td>{item.phone || ""}</td>
                    <td><img alt="Profile Display" src={item.photo ? "https://storage.googleapis.com/bucket-qr-codes/"+item.photo : "images/profile.png"}  className="image-profile rounded-circle" /></td>
                    <td>
                    {item.jerarquia === 2 ? "Administrador" 
                    : item.jerarquia === 1 ? "Control de Acceso" 
                    : "Mesero"}
                    </td>
                    <td><FaEdit onClick={() => handleShow(item.id)}  /> <TiDelete onClick={() => handleShowDelete(item.id)} /> </td>
                </tr>)
        )
      }
      </tbody>
    </Table>

        <Modal show={showAdd} onHide={handleCloseAdd}>
            <Modal.Header closeButton>
            <Modal.Title>Agregar usuario</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label htmlFor="input_nombre">Nombre</Form.Label>
                    <Form.Control 
                    id="input_nombre"
                    type="text" 
                    placeholder="Nombre completo"
                    onKeyUp={(e) => setInputNombre(e.target.value)}
                    required />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label htmlFor="input_username">Email</Form.Label>
                    <Form.Control 
                    id="input_username"
                    type="text" 
                    placeholder="Email"
                    onKeyUp={(e) => setInputUsername(e.target.value)}
                    required />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label htmlFor="input_phone">Teléfono</Form.Label>
                    <Form.Control 
                    id="input_phone"
                    type="phone" 
                    placeholder="Teléfono"
                    onKeyUp={(e) => setInputPhone(e.target.value)}
                    required />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label htmlFor="input_password">Password</Form.Label>
                    <Form.Control 
                    id="input_password"
                    type="password" 
                    autoComplete="new-password"
                    placeholder="Password"
                    onKeyUp={(e) => setInputPassword(e.target.value)}
                    required />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label htmlFor="input_password">Jerarquía</Form.Label>
                    <Form.Select aria-label="select" onChange={(e) => setInputJerarquia(e.target.value)}>
                        <option>Seleccione...</option>
                        <option value="2">Administrador</option>
                        <option value="1">Control de acceso</option>
                        <option value="0">Mesero</option>
                    </Form.Select>
                </Form.Group>

                {!guardando 
                ? (
                    <Button variant="primary" type="submit"  onClick={(handleSubmit)}>
                        Guardar
                    </Button>
                ) : 
                (
                    <Button variant="primary" type="submit" disabled  onClick={(handleSubmit)}>
                        Guardando...
                    </Button>
                )}
            
            </Modal.Body>
        </Modal>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>Editar usuario</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            
            <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label htmlFor="input_nombre">Nombre</Form.Label>
                    <Form.Control 
                    id="input_nombre"
                    type="text" 
                    placeholder="Nombre completo"
                    defaultValue={inputNombreEdit}
                    onKeyUp={(e) => setInputNombreEdit(e.target.value)}
                    required />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label htmlFor="input_username">Email</Form.Label>
                    <Form.Control 
                    id="input_username"
                    type="text" 
                    placeholder="Email"
                    defaultValue={inputUsernameEdit}
                    onKeyUp={(e) => setInputUsernameEdit(e.target.value)}
                    required />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label htmlFor="input_phone">Teléfono</Form.Label>
                    <Form.Control 
                    id="input_phone"
                    type="phone" 
                    placeholder="Teléfono"
                    defaultValue={inputPhoneEdit}
                    onKeyUp={(e) => setInputPhoneEdit(e.target.value)}
                    required />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label htmlFor="input_password">Cambiar password (opcional)</Form.Label>
                    <Form.Control 
                    id="input_password"
                    type="password" 
                    autoComplete="new-password"
                    placeholder="Password"
                    onKeyUp={(e) => setInputPasswordEdit(e.target.value)}
                    required />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label htmlFor="input_password">Jerarquía</Form.Label>
                    <Form.Select value={inputJerarquiaEdit} aria-label="select" onChange={(e) => setInputJerarquiaEdit(e.target.value)}>
                        <option>Seleccione...</option>
                        <option value="2">Administrador</option>
                        <option value="1">Control de acceso</option>
                        <option value="0">Mesero</option>
                    </Form.Select>
                </Form.Group>

                {
                    !guardando 
                    ? (
                        <Button variant="primary" type="submit"  onClick={(handleSubmitEdit)}>
                            Guardar
                        </Button>
                    ) 
                    : 
                    (
                        <Button variant="primary" type="submit" disabled  onClick={(handleSubmitEdit)}>
                            Guardando...
                        </Button>
                    )
                }

            
            </Modal.Body>
        </Modal>
        

        <Modal show={showDelete} onHide={handleCloseDelete}>
            <Modal.Header closeButton>
            <Modal.Title>Eliminar usuario</Modal.Title>
            </Modal.Header>
            <Modal.Body>¿Está seguro que desea eliminar este usuario?</Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDelete}>
                Cancelar
            </Button>
            <Button variant="danger" onClick={() => handleDelete()} >
                Confirmar
            </Button>
            </Modal.Footer>
        </Modal>
        
    </>
  );
}

export default TableUsuarios;